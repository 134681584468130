import { useEffect, useState } from 'react'
import _ from 'lodash'

export default function PricingCard({ plan, onSelect }) {
  return (
    <>
      <div className="box-pricing-item">
        <h3>{plan.title}</h3>
        <div className="box-info-price">
          <span className="text-price color-brand-2">${plan.price}</span>
        </div>
        <div className="border-bottom mb-30">
          <p className="text-desc-package mb-30">{plan.description}</p>
        </div>
        <ul className="list-package-feature mb-0 pb-0">
          {plan.items.map((item, index) => (
            <li
              key={item}
              className={index + 1 === plan.items.length ? 'mb-0 pb-0' : ''}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
