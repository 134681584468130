import { useEffect, useState } from 'react'
import { withAuthorization } from '../../session'
import Layout from '../../components/layout/Layout'
import { getPlans } from '../../db/plan'
import { updateUserPlan } from '../../db/user'
import PricingCard from './PricingCard'
import _ from 'lodash'

function Pricing(props) {
  const [plans, setPlans] = useState([])

  const user = props.user
  const db = props.firebase.getdb()

  useEffect(() => {
    getPlans(db).then((items) => {
      const _items = _.map(items, (item) => {
        const active = user.plan === item.id
        return { ...item, active }
      })
      setPlans([..._items])
    })
  }, [])

  const handleSelectPlan = (planId, active) => {
    if (!active) {
      updateUserPlan(db, user.uid, planId)
      const items = _.map(plans, (item) => {
        const active = planId === item.id
        return { ...item, active }
      })
      setPlans([...items])
    } else {
      updateUserPlan(db, user.uid, plans[0].id)
      const items = _.map(plans, (item, index) => {
        const active = index === 0
        return { ...item, active }
      })
      setPlans([...items])
    }
  }

  return (
    <>
      <Layout>
        <section className="section-box mt-90">
          <div className="container">
            <h2 className="text-center mb-15">Job Packages</h2>
            <div className="font-lg color-text-paragraph-2 text-center">
              Edit and publish job plans below
            </div>
            <div className="max-width-price">
              <div className="block-pricing mt-70">
                <div className="row">
                  {plans.map((plan) => (
                    <div className="col-xl-4 col-lg-6 col-md-6">
                      <PricingCard plan={plan} onSelect={handleSelectPlan} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(Pricing)
