import { withAuthorization } from '../../session'
import Layout from '../../components/layout/Layout'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminsTable from './AdminsTable'

function AdminsPage(props) {
  const db = props.firebase.getdb()

  return (
    <>
      <Layout>
        <AdminsTable db={db} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(AdminsPage)
