import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import moment from 'moment'

const JobCard = ({ job }) => {
  const date = moment(job.createdAt ? job.createdAt.toDate() : null).format(
    'MM/DD/YYYY',
  )
  return (
    <Link
      to={`/jobs/${job.id}`}
      className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"
    >
      <div className="card-grid-2 hover-up">
        <div className="card-grid-2-image-left">
          <span className="flash" />
          <div className="right-info text-start ellipsis-text">
            <Link className="name-job ellipsis-text" to={`/jobs/${job.id}`}>
              {job.company}
            </Link>

            <span className="location-small">
              {job.city}, {job.state}
            </span>
          </div>
        </div>
        <div className="card-block-info">
          <Link to={`/jobs/${job.id}`}>
            <h6 className="ellipsis-text">{job.title}</h6>
          </Link>

          <div className="mt-5">
            <span className="card-briefcase">{job.contract}</span>
            {date !== '' && <span className="card-time">{date}</span>}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: job.description }}
            className="card-description"
          />
          <div className="card-2-bottom mt-30">
            <div className="row">
              <div className="col-lg-12 col-12">
                <Link className="btn btn-default" to={`/jobs/${job.id}`}>
                  Edit Job
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default JobCard
