import { Link } from 'react-router-dom'

export default function MobileMenu({ handleToggle, isToggled }) {
  return (
    <>
      <div
        className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${isToggled ? 'sidebar-visible' : ''}`}
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-search mobile-header-border mb-30">
                <form action="#">
                  <input type="text" placeholder="Search…" />
                  <i className="fi-rr-search" />
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                {/* mobile menu start*/}
                <nav>
                  <ul className="main-menu">
                    <li>
                      <Link
                        className={
                          window.location.pathname == '/jobs' ||
                          window.location.pathname == '/post-job'
                            ? 'dashboard2 active'
                            : 'dashboard2'
                        }
                        to="/jobs"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/jobs.svg"
                          alt="jobBox"
                        />
                        <span className="name">Jobs</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          window.location.pathname == '/employers' ||
                          window.location.pathname == '/add-employer'
                            ? 'dashboard2 active'
                            : 'dashboard2'
                        }
                        to="/employers"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/candidates.svg"
                          alt="jobBox"
                        />
                        <span className="name">Employers</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          window.location.pathname == '/pricing'
                            ? 'dashboard2 active'
                            : 'dashboard2'
                        }
                        to="/pricing"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/tasks.svg"
                          alt="jobBox"
                        />
                        <span className="name">Pricing Plans</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          window.location.pathname == '/admins' ||
                          window.location.pathname == '/add-admin'
                            ? 'dashboard2 active'
                            : 'dashboard2'
                        }
                        to="/admins"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/candidates.svg"
                          alt="jobBox"
                        />
                        <span className="name">Admins</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          window.location.pathname == '/login'
                            ? 'dashboard2 active'
                            : 'dashboard2'
                        }
                        to="/login"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/logout.svg"
                          alt="jobBox"
                        />
                        <span className="name">Logout</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link href="/#">Profile</Link>
                  </li>
                  <li>
                    <Link href="/#">Work Preferences</Link>
                  </li>
                  <li>
                    <Link href="/#">Account Settings</Link>
                  </li>
                  <li>
                    <Link href="/#">Go Pro</Link>
                  </li>
                  <li>
                    <Link href="/page-signin">Sign Out</Link>
                  </li>
                </ul>
                <div className="mb-15 mt-15">
                  {' '}
                  <Link
                    className="btn btn-default icon-edit hover-up"
                    to="/post-job"
                  >
                    Post Job
                  </Link>
                </div>
              </div>
              <div className="site-copyright">
                Copyright {new Date().getFullYear()} © JobBox. <br />
                Designed by AliThemes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
