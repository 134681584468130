// const CareerjetKeywords = [
//   { value: 'Application Engineer', key: 'application-engineer' },
//   { value: 'IT Support', key: 'it-support' },
//   { value: 'Graphic Designer', key: 'graphic-designer' },
//   { value: 'Leader Software Engineer', key: 'leader-software-engineer' },
//   { value: 'Machine Learning Engineers', key: 'machine-learning-engineers' },
//   { value: 'Principal Engineer', key: 'principal-engineer' },
//   { value: 'Product Manager', key: 'product-manager' },
//   { value: 'R&D Engineer', key: 'rd-engineer' },
//   { value: 'Senior Software Development', key: 'senior-software-development' },
//   { value: 'Senior Software Engineer', key: 'senior-software-engineer' },
//   {
//     value: 'Software Development Engineer',
//     key: 'software-development-engineer',
//   },
//   { value: 'Software Engineer', key: 'software-engineer' },
//   { value: 'Staff Engineer', key: 'staff-engineer' },
//   { value: 'Strategy & Planning Manager', key: 'strategy-planning-manager' },
//   { value: 'Supply Chain Manager', key: 'supply-chain-manager' },
//   { value: 'Systems Engineer', key: 'systems-engineer' },
//   { value: 'UX/UI Designer', key: 'ux-ui-designer' },
//   { value: 'Application Developer', key: 'application-developer' },
//   { value: 'Data Scientist', key: 'data-scientist' },
//   { value: 'Engineer, IT Support', key: 'engineer-it-support' },
//   { value: 'Finance Analyst', key: 'finance-analyst' },
//   { value: 'Game Designer', key: 'game-designer' },
//   { value: 'Software QA Engineer', key: 'software-qa-engineer' },
//   {
//     value: 'Technical Solutions Engineer',
//     key: 'technical-solutions-engineer',
//   },
//   { value: 'Systems/Software Engineer', key: 'systems-software-engineer' },
// ]

const b = [
  { value: 'Application Engineer', industry: 'engineering' },
  {
    value: 'IT Support',
    industry: 'information-services-or-tech-it',
  },
  { value: 'Graphic Designer', industry: 'graphic-design' },
  {
    value: 'Leader Software Engineer',
    industry: 'software-development',
  },
  { value: 'Machine Learning Engineers', industry: 'data-science' },
  { value: 'Product Manager', industry: 'product-management' },
  { value: 'R&D Engineer', industry: 'research' },

  { value: 'Strategy & Planning Manager', industry: 'management' },
  {
    value: 'Supply Chain Manager',
    industry: 'supply-chain-or-logistics',
  },
  { value: 'UX/UI Designer', industry: 'design' },

  { value: 'Finance Analyst', industry: 'finance' },
  { value: 'Software QA Engineer', industry: 'quality-assurance' },
  { value: 'Technical Solutions Engineer', industry: 'engineering' },
  {
    value: 'Systems/Software Engineer',
    industry: 'software-development',
  },
  /// chatgpt proposals
  { value: 'HR Manager', industry: 'human-resources' },

  { value: 'Sales Representative', industry: 'sales' },

  { value: 'Corporate Lawyer', industry: 'legal' },

  { value: 'Digital Marketing Specialist', industry: 'marketing' },

  { value: 'Warehouse Manager', industry: 'warehousing-and-storage' },
  {
    value: 'Inventory Specialist',
    industry: 'warehousing-and-storage',
  },
  {
    value: 'Logistics Coordinator',
    industry: 'warehousing-and-storage',
  },
  { value: 'Cybersecurity Analyst', industry: 'security-analyst' },
  {
    value: 'Information Security Specialist',
    industry: 'security-analyst',
  },
  {
    value: 'Network Security Engineer',
    industry: 'security-analyst',
  },
  { value: 'Advertising Account Manager', industry: 'advertising' },
  { value: 'Media Buyer', industry: 'advertising' },
  { value: 'Copywriter', industry: 'advertising' },
  { value: 'Agronomist', industry: 'agriculture' },
  { value: 'Farm Manager', industry: 'agriculture' },
  { value: 'Agricultural Scientist', industry: 'agriculture' },
  { value: 'Data Analyst', industry: 'analysis' },
  { value: 'Business Analyst', industry: 'analysis' },
  { value: 'Market Research Analyst', industry: 'analysis' },
  {
    value: 'Certified Public Accountant (CPA)',
    industry: 'accounting',
  },
  { value: 'Financial Auditor', industry: 'accounting' },
  { value: 'Bookkeeper', industry: 'accounting' },
  { value: 'Molecular Biologist', industry: 'biological-science' },
  { value: 'Microbiologist', industry: 'biological-science' },
  { value: 'Biochemist', industry: 'biological-science' },
  {
    value: 'Business Development Executive',
    industry: 'business-development',
  },
  {
    value: 'Strategic Partnerships Manager',
    industry: 'business-development',
  },
  {
    value: 'Corporate Development Analyst',
    industry: 'business-development',
  },

  { value: 'Game Designer', industry: 'design' },
  { value: 'Pharmaceutical Researcher', industry: 'medical-science' },
  { value: 'Generalist', industry: 'other' },

  {
    value: 'Public Relations Specialist',
    industry: 'publish-relations',
  },

  {
    value: 'Property Manager',
    industry: 'real-state-or-property-management',
  },
  {
    value: 'Real Estate Broker',
    industry: 'real-state-or-property-management',
  },
  {
    value: 'Leasing Agent',
    industry: 'real-state-or-property-management',
  },
  {
    value: 'Customer Service Representative',
    industry: 'customer-help',
  },
  {
    value: 'Technical Support Specialist',
    industry: 'customer-help',
  },
  { value: 'Client Success Manager', industry: 'customer-help' },
  { value: 'Principal Engineer', industry: 'engineering' },
  {
    value: 'Senior Software Development',
    industry: 'software-development',
  },
  {
    value: 'Senior Software Engineer',
    industry: 'software-development',
  },
  {
    value: 'Software Development Engineer',
    industry: 'software-development',
  },
  {
    value: 'Application Developer',
    industry: 'software-development',
  },
  { value: 'Software Engineer', industry: 'software-development' },
  { value: 'Data Scientist', industry: 'data-science' },
  { value: 'Staff Engineer', industry: 'engineering' },
  { value: 'Systems Engineer', industry: 'engineering' },
  {
    value: 'Engineer, IT Support',
    industry: 'information-services-or-tech-it',
  },
  { value: 'Legal Advisor', industry: 'legal' },
  { value: 'Compliance Officer', industry: 'legal' },
  { value: 'Recruitment Specialist', industry: 'human-resources' },
  {
    value: 'Talent Acquisition Coordinator',
    industry: 'human-resources',
  },
  { value: 'Account Executive', industry: 'sales' },
  {
    value: 'Business Development Manager',
    industry: 'sales',
  },
  { value: 'Content Marketing Manager', industry: 'marketing' },
  {
    value: 'SEO Strategist',
    industry: 'marketing',
  },
  {
    value: 'Clinical Research Scientist',
    industry: 'medical-science',
  },
  {
    value: 'Medical Laboratory Technician',
    industry: 'medical-science',
  },
  { value: 'Consultant', industry: 'other' },
  {
    value: 'Freelance Specialist',
    industry: 'other',
  },
  { value: 'Communications Manager', industry: 'publish-relations' },
  {
    value: 'Media Relations Coordinator',
    industry: 'publish-relations',
  },
]

export default b
  .map((item) => {
    return { ...item, key: item.value.toLowerCase().replace(/\s+/g, '-') }
  })
  .sort((a, b) => (a.value > b.value ? 1 : -1))
