import { Collections } from '../constants/defines'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  limit,
} from 'firebase/firestore'

const deleteUser = (db, docId) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, Collections.USERS, docId))
      .then(() => {
        console.log('User successfully deleted!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting User: ', error)
        reject()
      })
  })
}

const createUser = (db, uid, data) => {
  const item = { ...data, uid }
  item.createdAt = new Date()
  item.signedIn = new Date()

  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, uid)
    setDoc(docRef, item)
      .then(() => {
        console.log('New User created with ID: ', uid)
        resolve(uid)
      })
      .catch((error) => {
        console.error('Error adding User: ', error)
        reject()
      })
  })
}

const getUser = (db, uid) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, uid)
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data() }
        resolve(data)
      } else {
        resolve(null)
      }
    })
  })
}

const getSnapUser = (db, uid) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, uid)
    onSnapshot(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data() }
        resolve(data)
      } else {
        resolve(null)
      }
    })
  })
}

const updateUser = (db, docId, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, docId)
    updateDoc(docRef, {
      ...item,
    })
      .then(() => {
        console.log('User Data successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Data: ', error)
        reject()
      })
  })
}

const updateUserignedIn = (db, docId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, docId)
    updateDoc(docRef, {
      signedIn: new Date(),
    })
      .then(() => {
        console.log('User Sign successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Sign: ', error)
        reject()
      })
  })
}

const updateUserPlan = (db, docId, planId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, docId)
    updateDoc(docRef, {
      plan: planId,
    })
      .then(() => {
        console.log('User PlanId successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User PlanId: ', error)
        reject()
      })
  })
}

const getUsers = (db, sortBy = 'desc') => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, Collections.USERS),
      where('isAdmin', '==', false),
      orderBy('createdAt', sortBy),
    )

    return getDocs(q)
      .then((querySnapshot) => {
        let items = []

        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id })
        })
        resolve(items)
      })
      .catch((error) => {
        console.log('getUsers: ', error)
        reject()
      })
  })
}

const getUsersV2 = (db, filters, limitPerPage) => {
  return getCollectionDocuments(db, filters, limitPerPage, Collections.USERS)
}

const getCollectionDocuments = async (
  db,
  filters,
  limitPerPage,
  collectionString,
) => {
  try {
    filters.push(limit(limitPerPage))
    const jobQuery = query(collection(db, collectionString), ...filters)
    const querySnapshot = await getDocs(jobQuery)
    let items = []

    let newLastVisible
    newLastVisible = querySnapshot.docs[limitPerPage - 1] ?? null
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id })
    })

    return { items, newLastVisible }
  } catch (error) {
    console.error('getJobs Failed:', error)
    return { items: null, newLastVisible: null }
  }
}

export {
  createUser,
  getUser,
  getSnapUser,
  deleteUser,
  updateUser,
  updateUserignedIn,
  updateUserPlan,
  getUsers,
  getUsersV2,
  getCollectionDocuments,
}
