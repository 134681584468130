import { initializeApp } from 'firebase/app'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
} from 'firebase/auth'
import { getFirestore, collection, getDocs } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

class Firebase {
  constructor() {
    const app = initializeApp(config)
    //firebase.firestore(app);
    this.auth = getAuth(app)
    this.db = getFirestore(app)
    this.storage = getStorage(app)
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password)

  doSignOut = () => {
    signOut(this.auth)
  }

  doPasswordReset = (email) => sendPasswordResetEmail(this.auth, email)

  doPasswordUpdate = (password) =>
    updatePassword(this.auth.currentUser, password)

  getdb = () => this.db
  getstorage = () => this.storage
  getFirebase = () => this
}

export default Firebase
