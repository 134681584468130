import { useCallback, useEffect, useState } from 'react'
import { startAfter } from 'firebase/firestore'
import EmployersHeaders from './ScraperHeader'
import EmployersFilters, { postedDays } from './ScrapersModal'
import { getUsers, getUsersV2 } from '../../db/user'
import { formatDate } from '../../modules/functions'
import { useNavigate } from 'react-router-dom'
import ScraperHeader from './ScraperHeader'
import ScrapersModal from './ScrapersModal'
import moment from 'moment'
import {
  listenScraperInstances,
  removeScraperInstance,
  updateScraperInstance,
} from '../../db/ScraperInstances'
import { withFirebase } from '../../firebase'

const now = moment()
const twoHoursAgo = now.subtract(2, 'hours')
const relativeTime = twoHoursAgo

const ScraperTable = ({ db }) => {
  const [items, setItems] = useState([])
  const [lastValue, setLastValue] = useState()
  const [localFilters, setLocalFilters] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    // listen
    return listenScraperInstances(db, (items) => setItems(items))
  }, [db])

  const onRemove = useCallback(
    (a) => async () => {
      await removeScraperInstance(a)
    },
    [],
  )

  const onRefreshItem = useCallback(
    (id) => async () => {
      await updateScraperInstance(id, {
        status: 'initializing',
        foundJobs: 0,
        updatedAt: new Date(),
        jobs: 0,
      })
    },
    [],
  )

  const onRefresh = useCallback(
    async (filters, lastVisible) => {
      setLocalFilters(filters)
      if (lastVisible) {
        filters.push(startAfter(lastVisible))
      }
      try {
        const { items, newLastVisible } = await getUsersV2(db, filters, 100)
        setItems(lastVisible ? [...items, ...items] : [...items])
        setLastValue(newLastVisible)
      } catch (e) {
        console.log(e)
      }
    },
    [items],
  )

  function handleClick(event, usreId) {
    //window.location.href = `/employers/${usreId}`;
    navigate(`/employers/${usreId}`)
  }

  const loadMore = useCallback(() => {
    onRefresh(localFilters, lastValue)
  }, [localFilters, lastValue])

  return (
    <>
      <ScraperHeader />
      <ScrapersModal />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Last Run</th>
            <th scope="col">Created</th>
            <th scope="col">Keyword</th>
            <th scope="col">City</th>
            <th scope="col">Posted Day</th>
            <th scope="col">Url</th>
            <th scope="col">Status</th>
            <th scope="col">Jobs</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item, index) => {
              const now = moment()
              const updateAt = moment(item.updatedAt.toDate())
              const differenceInHours = now.diff(updateAt, 'hours')
              console.log(differenceInHours)

              return (
                <tr key={`job-scraper-${item.id}-${index}`}>
                  <td>
                    {differenceInHours >= 24
                      ? moment(item.createdAt.toDate()).format('MM.DD.YY')
                      : moment(item.updatedAt.toDate()).fromNow()}
                  </td>
                  <td>{moment(item.createdAt.toDate()).format('MM.DD.YY')}</td>
                  <td>{item.keyword}</td>
                  <td>{item.city}</td>
                  <td>
                    {postedDays.find((it) => it.key === item?.postedDate).value}
                  </td>
                  <td>
                    {/*<a >*/}
                    {/*  {item?.url.split('/jobs/?')[1]}*/}
                    {/*</a>*/}

                    <a
                      className="btn-refresh mr-20"
                      href={item?.url}
                      target="_blank"
                    >
                      <img
                        src="assets/imgs/link.svg"
                        alt="jobBox"
                        style={{ minWidth: '25px', marginBottom: '-5px' }}
                      />
                    </a>
                  </td>
                  <td>{item.status}</td>
                  <td>
                    {item.jobs ?? '--'}
                    {item?.foundJobs ? '/' + item.foundJobs : ''}
                  </td>
                  <td style={{ fontWeight: '600' }}>
                    {(item.status === 'completed' ||
                      item.status === 'scrape-error') && (
                      <>
                        <button
                          className="btn-refresh mr-20"
                          onClick={onRefreshItem(item.id)}
                        >
                          <img
                            src="assets/imgs/refresh.svg"
                            alt="jobBox"
                            style={{ minWidth: '25px', marginBottom: '-5px' }}
                          />
                        </button>
                        <button
                          className="btn-refresh ml-20"
                          onClick={onRemove(item.id)}
                        >
                          <img
                            src="assets/imgs/times.svg"
                            alt="jobBox"
                            style={{ minWidth: '25px', marginBottom: '-5px' }}
                          />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      {items === undefined && <span>Loading...</span>}

      {lastValue && (
        <div
          className={
            'd-flex w-100 align-items-center justify-content-center mt-20'
          }
        >
          <button className=" btn btn-default" onClick={loadMore}>
            Load more
          </button>
        </div>
      )}

      {items && items.length === 0 && !lastValue && (
        <span className="mb-20 mt-20">There is no scraper history.</span>
      )}
    </>
  )
}
export default withFirebase(ScraperTable)
