import { Link } from 'react-router-dom'
import { useState, useContext } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import AuthUserContext from '../../session/context'
import FirebaseContext from '../../firebase/context'

export default function Sidebar() {
  const [isToggled, setToggled] = useState(false)
  const toggleTrueFalse = () => setToggled(!isToggled)
  const data = useContext(AuthUserContext)
  const firebaseContext = useContext(FirebaseContext)
  const user = data ? data.user : null

  const clickSignOut = (event) => {
    event.preventDefault()
    firebaseContext.doSignOut()
  }

  return (
    <>
      <div className={`nav ${isToggled ? 'close-nav' : ''}`}>
        <a
          className={`btn btn-expanded ${isToggled ? 'btn-collapsed' : ''}`}
          onClick={toggleTrueFalse}
        />
        <nav className="nav-main-menu">
          <ul className="main-menu">
            <li>
              <Link
                className={
                  window.location.pathname.includes('jobs') ||
                  window.location.pathname === '/post-job'
                    ? 'dashboard2 active'
                    : 'dashboard2'
                }
                to="/jobs"
              >
                <img src="/assets/imgs/page/dashboard/jobs.svg" alt="jobBox" />
                <span className="name">Jobs</span>
              </Link>
            </li>

            <li>
              <Link
                className={
                  window.location.pathname.includes('employers') ||
                  window.location.pathname === '/add-employer'
                    ? 'dashboard2 active'
                    : 'dashboard2'
                }
                to="/employers"
              >
                <img
                  src="/assets/imgs/page/dashboard/candidates.svg"
                  alt="jobBox"
                />
                <span className="name">Employers</span>
              </Link>
            </li>

            <li>
              <Link
                className={
                  window.location.pathname === '/pricing'
                    ? 'dashboard2 active'
                    : 'dashboard2'
                }
                to="/pricing"
              >
                <img src="/assets/imgs/page/dashboard/tasks.svg" alt="jobBox" />
                <span className="name">Pricing Plans</span>
              </Link>
            </li>

            <li>
              <Link
                className={
                  window.location.pathname === '/admins' ||
                  window.location.pathname === '/add-admin'
                    ? 'dashboard2 active'
                    : 'dashboard2'
                }
                to="/admins"
              >
                <img
                  src="/assets/imgs/page/dashboard/candidates.svg"
                  alt="jobBox"
                />
                <span className="name">Admins</span>
              </Link>
            </li>

            <li>
              <Link
                className={
                  window.location.pathname === '/job-scraper'
                    ? 'dashboard2 active'
                    : 'dashboard2'
                }
                to="/job-scraper"
              >
                <img
                  src="/assets/imgs/page/dashboard/cv-manage.svg"
                  alt="scraper"
                />
                <span className="name">Job Scraper</span>
              </Link>
            </li>

            <li>
              <a
                className={
                  window.location.pathname === '/login'
                    ? 'dashboard2 active'
                    : 'dashboard2'
                }
                href="#"
                onClick={clickSignOut}
              >
                <img
                  src="/assets/imgs/page/dashboard/logout.svg"
                  alt="jobBox"
                />
                <span className="name">Logout</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className="border-bottom mb-20 mt-20" />
      </div>
    </>
  )
}
