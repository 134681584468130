import React from 'react'
import { Link } from 'react-router-dom'

const ScraperHeader = () => {
  return (
    <div className="box-list-toolbar">
      <div className="row">
        <div className="col-4 d-flex">
          <img
            src="/assets/imgs/page/dashboard/cv-manage.svg"
            className="mr-15"
            style={{ width: '30px' }}
            alt="jobBox"
          />
          <h3 className="mt-1 text-left">Job Scraper</h3>
        </div>
        {/*<div className="col-8">*/}
        {/*  <div className="box-toolbar">*/}
        {/*    <button className="btn btn-default icon-edit hover-up">*/}
        {/*      Add Scrapper*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default ScraperHeader
