import React, { useCallback, useState } from 'react'
import CareerjetCities from '../../constants/CareerjetCities'
import SimpleSelect from '../../components/filter/SimpleSelect'
import CustomSelect from '../../components/filter/CustomSelect'
import CareerjetKeywords from '../../constants/CareerjetKeywords'
import { addScraperInstance } from '../../db/ScraperInstances'

export const postedDays = [
  { value: 'Last 24 hours', key: 'nw=1' },
  { value: 'Last 7 days', key: 'nw=7' },
  { value: 'Last 30 days', key: 'nw=30' },
]

const buildUrls = (config) => {
  const MAIN_URL = `https://www.careerjet.com/jobs/?s=%22${config.keyword.replace(/ /g, '+')}%22&`
  let [city, state] = config.city.split(',-')
  city = city.replace(/-/g, ' ')
  let url = MAIN_URL
  url += 'l=' + city.replace(/ /g, '+')
  url += '&radius=15'
  if (config.postedDate !== '') {
    url += '&' + config.postedDate
  }
  return url
}

const ScrapersModal = ({ firebase }) => {
  const [city, setCity] = useState('')
  const [keyword, setKeyword] = useState('')
  const [lastDays, setLastDays] = useState('')

  const onRunScraper = useCallback(async () => {
    const item = CareerjetKeywords.find((it) => {
      return it.key === keyword
    })

    const payload = {
      scraperType: 'careerjet',
      createdAt: new Date(),
      updatedAt: new Date(),
      city: city,
      industry: item.industry,
      status: 'initializing',
      keyword: keyword,
      postedDate: lastDays,
    }
    const url = buildUrls(payload)
    await addScraperInstance({ url, ...payload })

    setCity('')
    setLastDays('')
    setKeyword('')
  }, [city, keyword, firebase, lastDays])

  const enableButton = city !== '' && keyword !== '' && lastDays !== ''
  return (
    <div className="grid-toolbar">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          marginBottom: '.5rem',
          justifyContent: 'space-between',
        }}
      >
        <div className="box-toolbar" style={{ marginLeft: '-1rem' }}>
          <SimpleSelect
            onSelect={setCity}
            value={city}
            items={CareerjetCities}
            placeholder="Choose City"
            label="City"
            width={300}
          />

          <CustomSelect
            onSelect={setKeyword}
            value={keyword}
            items={CareerjetKeywords}
            placeholder="Choose Keyword"
            label="keyword"
            width={300}
          />
          <CustomSelect
            onSelect={setLastDays}
            value={lastDays}
            items={postedDays}
            placeholder="Choose Date"
            label="Date Posted"
            width={160}
          />
          <div className="box-toolbar ml-20">
            <button
              onClick={onRunScraper}
              className={`btn btn-default icon-edit hover-up ${enableButton ? '' : 'disabled'}`}
            >
              Run Scrapper
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScrapersModal
