import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { withAuthorization } from '../../session'
import Layout from '../../components/layout/Layout'
import { getUserJobs } from '../../db/job'
import { getUser, deleteUser, updateUser } from '../../db/user'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IndustryTypes } from '../../constants/defines'
import { useSearchParams } from 'react-router-dom'
import UserJobsTab from './UserJobsTab'
import UserProfileTab from './UserProfileTab'

const EditProfile = (props) => {
  let { id } = useParams()
  const [searchParams] = useSearchParams()
  const [activeIndex, setActiveIndex] = useState(
    searchParams.get('tab') ?? 'profile',
  )
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const db = props.firebase.getdb()

  useEffect(() => {
    setActiveIndex(searchParams.get('tab') ?? 'profile')
  }, [searchParams])

  useEffect(() => {
    setLoading(true)
    getUser(db, id).then((item) => {
      setUser({ ...item })
      setLoading(false)
    })
  }, [])

  const handleDelete = () => {
    deleteUser(db, id).then(() => {
      toast.success('Delete successful!')
      window.Location.href = '/employers'
    })
  }

  const onSubmit = (user) => {
    updateUser(db, id, user).then(() => {
      setUser(user)
      toast.success('Update successful!')
    })
  }

  const handleNavClick = (index) => {
    setActiveIndex(index) // remove the curly braces
  }

  if (!user) return <div>Loading...</div>

  const tabs = [
    {
      id: 'profile',
      value: 'Profile',
      component: <UserProfileTab user={user} onSubmit={onSubmit} />,
    },
    { id: 'jobs', value: 'Job Posts', component: <UserJobsTab id={id} /> },
  ]
  return (
    <Layout>
      <div className="section-link">
        <Link
          className="btn icon-back btn-back"
          to={user?.isAdmin ? '/admins' : '/employers'}
        >
          {user?.isAdmin ? 'All Admins' : 'All Employers'}
        </Link>
      </div>

      {!loading && (
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-12">
            <div className="mb-30 text-start">
              <h3 className="text-name">{user.name}</h3>
              <div className="text-company">{user.company}</div>
            </div>
            <div className="box-nav-tabs nav-tavs-profile mb-5">
              <ul className="nav" role="tablist">
                {tabs.map((tabData) => {
                  return (
                    <li>
                      <button
                        className={`btn btn-nav icon-jobs hover-up ${activeIndex === tabData.id && 'active'}`}
                        onClick={() => handleNavClick(tabData.id)}
                      >
                        {tabData.value}
                      </button>
                    </li>
                  )
                })}
              </ul>
              <div className="border-bottom pt-10 pb-10" />
              <div className="mt-20 mb-20">
                <button className="link-red" onClick={handleDelete}>
                  Delete Account
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-md-8 col-sm-12 col-12 mb-50">
            <div className="content-single text-start">
              <div className="tab-content">
                {tabs.map((tabData) => {
                  return (
                    <div
                      className={`tab-pane fade ${activeIndex === tabData.id && 'show active'}`}
                    >
                      {tabData.component}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Layout>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(EditProfile)
