import React, { useCallback, useEffect, useState } from 'react'
import JobCard from '../../components/elements/JobCard'
import { getJobsV3 } from '../../db/job'
import { withFirebase } from '../../firebase'
import { orderBy, where } from 'firebase/firestore'

const UserJobsTab = ({ id, firebase }) => {
  const [jobs, setJobs] = useState(undefined)
  const [limit, setLimit] = useState(100)
  const [lastVisible, setLastVisible] = useState()

  useEffect(() => {
    getUserJobs()
  }, [lastVisible])

  const getUserJobs = useCallback(
    async (currentLastVisible) => {
      const { items, newLastVisible } = await getJobsV3(
        firebase.getdb(),
        [where('userId', '==', id), orderBy('createdAt')],
        limit,
      )
      setJobs(items)
      setLastVisible(newLastVisible)
    },
    [id, limit],
  )

  if (!jobs) return <span>Loading...</span>

  if (jobs.length === 0) return <span>No Jobs Found.</span>

  return (
    <div className="row form-jobs">
      {jobs.map((row, i) => {
        return (
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
            <JobCard job={row} />
          </div>
        )
      })}
    </div>
  )
}

export default withFirebase(UserJobsTab)
