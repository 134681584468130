import { useCallback, useState } from 'react'
import { startAfter } from 'firebase/firestore'
import { getUsersV2 } from '../../db/user'
import { formatDate } from '../../modules/functions'
import { useNavigate } from 'react-router-dom'
import AdminsHeader from './AdminsHeader'
import AdminsFilter from './AdminsFilter'

const AdminsTable = ({ db }) => {
  const [employers, setEmployers] = useState(undefined)
  const [lastValue, setLastValue] = useState()
  const [localFilters, setLocalFilters] = useState()
  const navigate = useNavigate()

  const onRefresh = useCallback(
    async (filters, lastVisible) => {
      setLocalFilters(filters)
      if (lastVisible) {
        filters.push(startAfter(lastVisible))
      }
      try {
        const { items, newLastVisible } = await getUsersV2(db, filters, 100)
        setEmployers(lastVisible ? [...employers, ...items] : [...items])
        setLastValue(newLastVisible)
      } catch (e) {
        console.log(e)
      }
    },
    [employers],
  )

  function handleClick(event, usreId) {
    //window.location.href = `/employers/${usreId}`;
    navigate(`/employers/${usreId}`)
  }

  const loadMore = useCallback(() => {
    onRefresh(localFilters, lastValue)
  }, [localFilters, lastValue])

  return (
    <>
      <AdminsHeader />
      <AdminsFilter onChange={onRefresh} />
      {employers && employers.length !== 0 && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Company</th>
              <th scope="col">Registration Date</th>
              <th scope="col">Location</th>
            </tr>
          </thead>
          <tbody>
            {employers &&
              employers.map((item, index) => {
                return (
                  <tr
                    key={`employers-table-${item.name}-${index}`}
                    onClick={(event) => handleClick(event, item.id)}
                  >
                    <td style={{ fontWeight: '500' }}>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.company}</td>
                    <td>{formatDate(item.createdAt)}</td>
                    <td>{`${item.state}, ${item.city}`}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      )}
      {employers === undefined && <span>Loading...</span>}
      {employers && employers.length === 0 && (
        <span className="mb-20">No Employers Found </span>
      )}

      {lastValue && (
        <div
          className={
            'd-flex w-100 align-items-center justify-content-center mt-20'
          }
        >
          <button className=" btn btn-default" onClick={loadMore}>
            Load more
          </button>
        </div>
      )}

      {employers && employers.length === 0 && !lastValue && (
        <span className="mb-20 mt-20">There is no more documents to show.</span>
      )}
    </>
  )
}
export default AdminsTable
