import React from 'react'
import { Link } from 'react-router-dom'

const JobsHeader = () => {
  return (
    <div className="box-list-toolbar">
      <div className="row">
        <div className="col-4 d-flex">
          <img
            src="/assets/imgs/page/dashboard/jobs.svg"
            className="mr-15"
            style={{ width: '30px' }}
            alt="jobBox"
          />
          <h3 className="mt-1 text-left">All Jobs</h3>
        </div>
        <div className="col-8">
          <div className="box-toolbar">
            <Link className="btn btn-default icon-edit hover-up" to="/post-job">
              Add New Job
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobsHeader
