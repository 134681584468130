import JobCard from '../../components/elements/JobCard'
import { useCallback, useState } from 'react'
import _ from 'lodash'
import JobFilters from './JobFilters'
import { getJobsV3 } from '../../db/job'
import { orderBy, where, startAfter, startAt } from 'firebase/firestore'
import { useWindowSize, useWindowScroll } from '@uidotdev/usehooks'
import JobsHeader from './JobsHeader'

const JobsGrid = ({ db }) => {
  const [jobs, setJobs] = useState([])
  const [lastValue, setLastValue] = useState()
  const [localFilters, setLocalFilters] = useState()

  const onRefresh = useCallback(
    async (filters, lastVisible) => {
      setLocalFilters(filters)
      if (lastVisible) {
        filters.push(startAfter(lastVisible))
      }
      try {
        const { items, newLastVisible } = await getJobsV3(db, filters, 16)
        setJobs(lastVisible ? [...jobs, ...items] : [...items])
        setLastValue(newLastVisible)
      } catch (e) {
        console.log(e)
      }
    },
    [jobs],
  )

  const loadMore = useCallback(() => {
    onRefresh(localFilters, lastValue)
  }, [localFilters, lastValue])

  const refresh = useCallback(() => {}, [])

  return (
    <>
      <JobsHeader />
      <JobFilters onChange={onRefresh} />
      <div className="row form-jobs ">
        {jobs === undefined && <span>Loading...</span>}
        {jobs && jobs.length === 0 && (
          <span className="mb-20">No Jobs Found </span>
        )}
        {jobs &&
          jobs.length !== 0 &&
          jobs.map((row, i) => {
            return (
              <div className="col-xl-3 col-md-4 col-sm-6 col-12" key={i}>
                <JobCard job={row} />
              </div>
            )
          })}

        {lastValue && (
          <div
            className={'d-flex w-100 align-items-center justify-content-center'}
          >
            <button className=" btn btn-default" onClick={loadMore}>
              Load more
            </button>
          </div>
        )}

        {jobs && jobs.length === 0 && !lastValue && (
          <span className="mb-20 mt-20">
            There is no more documents to show.
          </span>
        )}
      </div>
    </>
  )
}
export default JobsGrid
