import CustomSelect from '../../components/filter/CustomSelect'
import React, { useEffect, useState } from 'react'
import { orderBy, where } from 'firebase/firestore'
import { JobTypes, SortTypes, StatusJobTypes } from '../../constants/defines'
import { useDebounce } from '@uidotdev/usehooks'

const JobsFilters = ({ onChange }) => {
  const [sort, setSort] = useState('most-recent')
  const [limit, setLimit] = useState(15)
  const [jobType, setJobType] = useState('all')
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 700)

  useDebounce()
  useEffect(() => {
    let filters = []

    // STATUS

    if (status === 'published') {
      filters.push(where('status', '==', 'published'))
    } else if (status === 'draft') {
      filters.push(where('status', '==', 'draft'))
    }

    // if (status === 'expired') {
    //   filters.push(where('status', '!=', ''))
    //   filters.push(where('expiration', '>=', new Date()))
    // }

    if (debouncedSearch) {
      filters.push(where('title', '>=', debouncedSearch))
    }

    // JOB TYPE
    if (jobType === 'all') {
    } else if (jobType === 'employer-jobs') {
      filters.push(where('isEmployer', '==', true))
    } else if (jobType === 'admin-jobs') {
      filters.push(where('isAdmin', '==', true))
    } else if (jobType === 'scraper-jobs') {
      filters.push(where('scrapper', '==', true))
    }

    // SORT
    if (sort === 'most-recent') {
      filters.push(orderBy('createdAt', 'desc'))
    } else if (sort === 'least-recent') {
      filters.push(orderBy('createdAt'))
    }

    onChange(filters)
  }, [sort, limit, jobType, status, debouncedSearch])

  return (
    <div className="grid-toolbar">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          marginBottom: '.5rem',
          justifyContent: 'space-between',
        }}
      >
        <div className="col-3">
          <div className="box-search-user">
            <input
              className="form-control input-search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="keyword"
              placeholder="By job title"
            />
          </div>
        </div>

        <div className="box-toolbar">
          <CustomSelect
            onSelect={setJobType}
            value={jobType}
            items={JobTypes}
            label="Job Type"
            defaultValue={'all'}
            width={130}
          />
          <CustomSelect
            onSelect={setSort}
            value={sort}
            items={SortTypes}
            label="Sort"
            width={150}
          />
        </div>
      </div>
    </div>
  )
}

export default JobsFilters
