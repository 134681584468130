import React from 'react'
import placeholder from 'lodash/fp/placeholder'

const SimpleSelect = ({
  onSelect,
  value,
  items,
  label,
  width = 100,
  defaultValue = '',
  disabled = false,
  placeholder = 'Choose an option',
}) => {
  return (
    <div
      className={`form-custom-select ${value !== defaultValue ? 'focus-active' : ''}`}
    >
      <label>{label}:</label>
      <select
        className={`custom-select `}
        style={{ width: width + 'px' }}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onSelect(e.target.value)
        }}
      >
        <option value={''} disabled>
          {placeholder}
        </option>
        {items.map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SimpleSelect
